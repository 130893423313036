import React from 'react';

import developmentImage1 from 'assets/images/pages/diensten_development/1.jpg';
import developmentImage2 from 'assets/images/pages/diensten_development/2.jpg';
import developmentImage3 from 'assets/images/pages/diensten_development/3.jpg';

import Title from 'blocks/Title';
import Image from 'blocks/Image';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';

const DienstenBouwenDevelopment = () => (
  <Layout>
    <Seo image={{ src: developmentImage1 }} />

    <Title
      image={{ src: developmentImage1 }}
      alt="Magento developer nodig?"
      subtitle="Jouw project, onze uitdaging"
      title="Magento developer nodig?"
    >
      Op zoek naar een goede Magento developer? Bij Batao kun je terecht met al
      je Magento uitdagingen. Ons zeer ervaren en gekwalificeerde team staat
      voor je klaar om welke Magento opdracht dan ook aan te gaan.
    </Title>

    <Section>
      <Image
        image={{ src: developmentImage2 }}
        alt="Dé Magento developer van Nederlandse bodem"
      >
        <Heading size="xl" as="h2">
          Batao: dé Magento developer van Nederlandse bodem.
        </Heading>

        <Text>
          Ons development team, dat we overigens volledig in-house hebben en
          vanuit ons kantoor in Nijmegen werkt, is volledig gespecialiseerd in
          Magento-development. Wij hebben zowel Magento 1 als Magento 2
          developers in huis, waarbij iedereen de benodigde certificeringen
          heeft. Hun kunde bewijst zich niet alleen doordat zij steengoede
          webshops bouwen, maar ook omdat we revolutionaire B2B Magento modules
          zoals Cart2Quote ontwikkelen.
        </Text>
      </Image>
    </Section>

    <Section>
      <Image
        image={{ src: developmentImage3 }}
        alt="Goed opgeleid en kritisch team"
        position="right"
      >
        <Heading size="xl" as="h2">
          Goed opgeleid en kritisch team op ons eigen kantoor
        </Heading>

        <Text>
          Dat zorgt niet alleen op het gebied van kwaliteit voor voordelen, maar
          ook in de communicatie. WIj vinden het belangrijk om nauw samen te
          werken en vinden korte lijntjes en snelle communicatie daarbij erg
          belangrijk. Wij willen de organisaties waarvoor wij mogen werken eerst
          goed leren kennen, voordat we aan de slag gaan. Pas dan kunnen we iets
          ontwikkelen dat daadwerkelijk past bij het merk.
        </Text>
      </Image>
    </Section>

    <ContactCta />
  </Layout>
);

export default DienstenBouwenDevelopment;
